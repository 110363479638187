<template>
  <div>
    <header-main />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderMain from '@/components/HeaderMain'
export default {
  name: 'defaultLayout',
  components: { HeaderMain }
}
</script>